import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Container from '../components/layout/container';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/layout/seo';
import ContentSections from '../components/pagebuilder/content-sections';
import { bgHeader } from '../components/layout/layout.module.css';
import { hasHeroSection } from '../utils/tools';

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      slug {
        current
      }
      title
      seoImage {
        asset {
          ... on SanityImageAsset {
            _id
            url
            gatsbyImageData(layout: FIXED, width: 1024)
          }
        }
      }
      seoKeywords
      seo {
        focus_keyword
        meta_description
        seo_title
      }
      content: contentArray {
        ...PageContent
      }
    }
  }
`;

const PageTemplate = (props) => {
  // scroll to top of page by default overriding gatsby router trying to restore scroll position
  useEffect(() => {
    if (!window.location.hash) {
      window.scrollTo(0, 0);
    }
  }, [props.data]);
  const {
    data: { page },
    errors,
  } = props;

  const { title, content, seo, seoImage, slug } = page;
  const seoDescription = (seo && seo.meta_description) || '';
  const pageTitle = title || 'Untitled';
  const seoTitle = (seo && seo.seo_title) || pageTitle;

  return (
    <>
      {errors && <SEO title="GraphQL Error" />}
      {page && (
        <SEO
          title={title}
          seoImage={seoImage?.asset?.img?.src}
          seoTitle={seoTitle}
          description={seoDescription}
          keywords={page.seoKeywords}
        />
      )}
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {!hasHeroSection(content) && <div className={bgHeader} />}
      {content?.length && <ContentSections location={props.location} content={content} serverData={props.serverData} />}
    </>
  );
};

export default PageTemplate;

export async function getServerData({ query }) {
  const { jobTitle } = query;
  return {
    props: {
      jobTitle,
    },
  };
}
